import React from 'react'

export const ArrowRight = ({fillColor}) => (<svg
  width={30}
  height={20}
>
  <path
    d='m 29.064972,10.642528 c 0,-0.183395 -0.09845,-0.358547 -0.262247,-0.490426 L 19.942736,3.2840846 c -0.34996,-0.2713138 -0.91652,-0.270627 -1.265585,0 -0.34996,0.2706269 -0.34996,0.710224 0,0.9808509 l 7.333053,5.6838531 H 1.3187676 c -0.49406139,0 -0.89503879,0.3104654 -0.89503879,0.6937394 0,0.383274 0.4009774,0.693739 0.89503879,0.693739 H 26.009309 l -7.332158,5.683853 c -0.34996,0.270627 -0.349065,0.710224 0,0.980851 0.34996,0.270627 0.91652,0.270627 1.265585,0 l 8.859989,-6.868018 c 0.167373,-0.129818 0.259562,-0.308405 0.262247,-0.490425 z'
    fill={fillColor}
    />
</svg>)

export const TwinkleStars = ({fillColor}) => (
  <svg
    width={38}
    height={48}
  >
    <g
      transform={'scale(1.4)'}
    >
      <path
        d='M13.76,15.64 L16,15 L13.76,14.36 L13.76,14.36 L12.5,11 L11.24,14.36 L9,15 L11.24,15.64 L12.5,19 L13.76,15.64 L13.76,15.64 Z M23.7142857,9.23809524 L22.5,6 L21.2857143,9.23809524 L19,10 L21.2857143,10.7619048 L22.5,14 L23.7142857,10.7619048 L26,10 L23.7142857,9.23809524 L23.7142857,9.23809524 L23.7142857,9.23809524 Z M21.7142857,21.2380952 L20.5,18 L19.2857143,21.2380952 L17,22 L19.2857143,22.7619048 L20.5,26 L21.7142857,22.7619048 L24,22 L21.7142857,21.2380952 L21.7142857,21.2380952 L21.7142857,21.2380952 Z M14.4594595,13.4864865 L12.5,8 L10.5405405,13.4864865 L6,15 L10.5405405,16.5135135 L12.5,22 L14.4594595,16.5135135 L19,15 L14.4594595,13.4864865 L14.4594595,13.4864865 L14.4594595,13.4864865 Z'
        fill={fillColor}
      />
    </g>
  </svg>
)
