import React from 'react'
import { css } from '@emotion/core'
import { useTheme } from './Theming'

import { TwitterShareButton, FacebookShareButton } from 'react-share'
import {ArrowRight, TwinkleStars} from '../lib/svg'

const SocialRow = ({primaryColor, children}) => (
  <div
    css={css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        div {
          margin-right: 20px;
          cursor: pointer;
          :hover {
            color: ${primaryColor};
          }
        }
        span {
          margin-right: 20px;
          font-size: 70%;
          text-transform: uppercase;
          line-height: 2.5;
          opacity: 0.7;
        }
      `}
  >
    <div
      css={css`
          flex-grow: 1;
        `}
    />{
    children
  }
  </div>
)

const TwitterDiscuss = ({url, textColor}) => (
  <div
    css={css`
        text-align: right;
        color: ${textColor};
        padding-right: 10px;
    `}
  >
    <a
      target={`neohed_discuss`}
      href={`https://twitter.com/search?q=${encodeURI(url)}`}>
      Discuss on twitter
    </a>
  </div>);

const Share = ({ url, title, twitterHandle }) => {
  const theme = useTheme()
  return (
    <div>
      <SocialRow
        key={'sr1'}
        primaryColor={theme.colors.primary}
        children={[
          <div
            key={'x1'}
            css={css`
        svg {
          opacity: 0.9;
          cursor: default;
        }
        `}>
            <TwinkleStars
              key={'s1'}
              fillColor={theme.colors.primary}
            />
          </div>,
          <TwitterDiscuss
            key={'s2'}
            url={url}
            textColor={theme.colors.primary}
          />
        ]}
      />
      <SocialRow
        key={'sr2'}
        primaryColor={theme.colors.primary}
        children={[
          <span key={'s1'}>Share article</span>,
          <div key={'x2'} css={css`
        svg {
          opacity: 0.5;
          cursor: default;
        }
        `}>
            <ArrowRight
              key={'s2'}
              fillColor={theme.colors.primary}
            />
          </div>,
          <TwitterShareButton
            key={'s3'}
            url={url}
            quote={title}
            via={twitterHandle.split('@').join('')}
          >
            Twitter
          </TwitterShareButton>,
          <FacebookShareButton
            key={'s4'}
            url={url}
            quote={title}
            via={twitterHandle.split('@').join('')}
            css={css`
          cursor: pointer;
        `}
          >
            Facebook
          </FacebookShareButton>
        ]}
      />
    </div>
  )
}

export default Share
